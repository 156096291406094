import { ICallToAction } from '@components/Widgets/types';

import { DeviceTypes } from './MobileChecker';

// all widget combinations come from these basic widgets
export enum WIDGET_TYPES {
  MOBILEAPPIOS = 'MOBILEAPPIOS',
  MOBILEAPPANDROID = 'MOBILEAPPANDROID',
  MOBILEAPPIOSQRCODE = 'MOBILEAPPIOSQRCODE',
  MOBILEAPPANDROIDQRCODE = 'MOBILEAPPANDROIDQRCODE',
  ONLINEBANKING = 'ONLINEBANKING',
  TELEFONBUTTON = 'TELEFONBUTTON',
  TELEFONQRCODE = 'TELEFONQRCODE',
  DIRECTIONS = 'DIRECTIONS',
  GENERICS = 'GENERICS',
  BANNER = 'BANNER',
  NONE = 'NONE',
}

// only these widgets should be rendered inline
export type INLINE_WIDGET_TYPES =
  | WIDGET_TYPES.GENERICS
  | WIDGET_TYPES.DIRECTIONS
  | WIDGET_TYPES.ONLINEBANKING;

export const INLINE_WIDGET_TYPES = {
  [WIDGET_TYPES.GENERICS]: WIDGET_TYPES.GENERICS,
  [WIDGET_TYPES.DIRECTIONS]: WIDGET_TYPES.DIRECTIONS,
  [WIDGET_TYPES.ONLINEBANKING]: WIDGET_TYPES.ONLINEBANKING,
} as const;

// these widgets are rendered over the results
export type TOP_WIDGET_TYPES =
  | Exclude<WIDGET_TYPES, INLINE_WIDGET_TYPES>
  | WIDGET_TYPES.ONLINEBANKING;

export const isMobileApp = (obj: ICallToAction): boolean =>
  obj.category === 'ios application' || obj.category === 'android application';

export const isOnlineBanking = (obj: ICallToAction): boolean =>
  obj.category === 'online banking';

export const isCustomerService = (obj: ICallToAction): boolean =>
  obj.category === 'customer service';

export const isGeneric = (obj: ICallToAction): boolean =>
  obj.category === 'unspecified';

export const isDirections = (obj: ICallToAction): boolean =>
  obj.category === 'directions';

export const isBanner = (obj: ICallToAction): boolean =>
  obj.category === 'banner';

//WIDGET COMBINATIONS

const isMobileAppWidget = (arr: Array<ICallToAction>): boolean =>
  arr.every((x) => isMobileApp(x));

const isOnlineBankingWidget = (arr: Array<ICallToAction>): boolean =>
  arr.every((x) => isOnlineBanking(x));

const isBankingTelefonWidget = (arr: Array<ICallToAction>): boolean =>
  arr.some((x) => isOnlineBanking(x)) &&
  arr.some((x) => isCustomerService(x)) &&
  !arr.some((x) => isMobileApp(x));

const isAppBankingTelefonWidget = (arr: Array<ICallToAction>): boolean =>
  arr.some((x) => isOnlineBanking(x)) &&
  arr.some((x) => isCustomerService(x)) &&
  arr.some((x) => isMobileApp(x));

const isDirectionsWidget = (arr: Array<ICallToAction>): boolean =>
  arr.every((x) => isDirections(x));

const isDirectionsTelefonWidget = (arr: Array<ICallToAction>): boolean =>
  arr.some((x) => isDirections(x)) && arr.some((x) => isCustomerService(x));

const isGenericWidget = (arr: Array<ICallToAction>): boolean =>
  arr.every((x) => isGeneric(x));

const isBannerWidget = (arr: Array<ICallToAction>): boolean =>
  arr.every((x) => isBanner(x));

export const isInlineWidget = (
  x: WIDGET_TYPES[],
): x is INLINE_WIDGET_TYPES[] => {
  return x.some(
    (x) =>
      x === INLINE_WIDGET_TYPES[WIDGET_TYPES.DIRECTIONS] ||
      x === INLINE_WIDGET_TYPES[WIDGET_TYPES.GENERICS] ||
      x === INLINE_WIDGET_TYPES[WIDGET_TYPES.ONLINEBANKING],
  );
};

export const isTopWidget = (x: WIDGET_TYPES[]): x is TOP_WIDGET_TYPES[] => {
  return x.some(
    (x) =>
      x === WIDGET_TYPES[WIDGET_TYPES.MOBILEAPPIOS] ||
      x === WIDGET_TYPES[WIDGET_TYPES.MOBILEAPPANDROID] ||
      x === WIDGET_TYPES[WIDGET_TYPES.MOBILEAPPIOSQRCODE] ||
      x === WIDGET_TYPES[WIDGET_TYPES.MOBILEAPPANDROIDQRCODE] ||
      x === WIDGET_TYPES[WIDGET_TYPES.ONLINEBANKING] ||
      x === WIDGET_TYPES[WIDGET_TYPES.TELEFONBUTTON] ||
      x === WIDGET_TYPES[WIDGET_TYPES.TELEFONQRCODE] ||
      x === WIDGET_TYPES[WIDGET_TYPES.BANNER] ||
      x === WIDGET_TYPES[WIDGET_TYPES.NONE],
  );
};

// this determine which widget combination will be rendered
// this logic is used for both UI and tracking
export const findWidgetsToRender = (
  arr: Array<ICallToAction>,
  mobileOs: DeviceTypes,
): WIDGET_TYPES[] => {
  if (arr.length > 0) {
    const isMobile = mobileOs !== DeviceTypes.DESKTOP;
    const isAndroid = mobileOs === DeviceTypes.ANDROID;
    const isIos = mobileOs === DeviceTypes.IOS;
    const isDesktop = mobileOs === DeviceTypes.DESKTOP;
    if (isMobileAppWidget(arr)) {
      if (isAndroid) return [WIDGET_TYPES.MOBILEAPPANDROID];
      if (isIos) return [WIDGET_TYPES.MOBILEAPPIOS];
      if (isDesktop)
        return [
          WIDGET_TYPES.MOBILEAPPIOSQRCODE,
          WIDGET_TYPES.MOBILEAPPANDROIDQRCODE,
        ];
    }
    if (isOnlineBankingWidget(arr)) {
      return [WIDGET_TYPES.ONLINEBANKING];
    }
    if (isBankingTelefonWidget(arr)) {
      const specificProductPageRegEx = /baufinanzierung|ratenkredit/gi;
      const isBaufinanzierungOrRatenkredit =
        arr[0].url && specificProductPageRegEx.test(arr[0].url);

      if (!isMobile) {
        if (!isBaufinanzierungOrRatenkredit) {
          return [WIDGET_TYPES.ONLINEBANKING, WIDGET_TYPES.TELEFONQRCODE];
        }
        return [WIDGET_TYPES.ONLINEBANKING];
      }
      return [WIDGET_TYPES.ONLINEBANKING, WIDGET_TYPES.TELEFONBUTTON];
    }
    if (isAppBankingTelefonWidget(arr)) {
      if (!isMobile) {
        return [WIDGET_TYPES.ONLINEBANKING, WIDGET_TYPES.TELEFONQRCODE];
      }
      if (isAndroid) {
        return [
          WIDGET_TYPES.MOBILEAPPANDROID,
          WIDGET_TYPES.TELEFONBUTTON,
          WIDGET_TYPES.ONLINEBANKING,
        ];
      }
      return [
        WIDGET_TYPES.MOBILEAPPANDROID,
        WIDGET_TYPES.TELEFONBUTTON,
        WIDGET_TYPES.ONLINEBANKING,
      ];
    }
    if (isDirectionsWidget(arr)) {
      return [WIDGET_TYPES.DIRECTIONS];
    }
    if (isDirectionsTelefonWidget(arr)) {
      if (!isMobile) {
        return [WIDGET_TYPES.DIRECTIONS, WIDGET_TYPES.TELEFONQRCODE];
      }
      return [WIDGET_TYPES.DIRECTIONS, WIDGET_TYPES.TELEFONBUTTON];
    }
    if (isGenericWidget(arr)) {
      return [WIDGET_TYPES.GENERICS];
    }
    if (isBannerWidget(arr)) {
      return [WIDGET_TYPES.BANNER];
    }
    return [];
  }

  return [];
};

import {
  trackSelfDescribingEvent,
  SelfDescribingJson,
  enableActivityTrackingCallback,
  BrowserTracker,
  newTracker,
  enableActivityTracking,
  setOptOutCookie,
  trackPageView,
} from '@snowplow/browser-tracker';
import { useFeature } from 'flagged';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from '@snowplow/browser-plugin-link-click-tracking';
import { SiteTrackingPlugin } from '@snowplow/browser-plugin-site-tracking';

import { SuggestionResultType } from '@components/Search';

import { WIDGET_TYPES } from './WidgetChecker';

type DictionaryData = OneKey<ObjKey, string>;
type DictionaryKey = 'result' | 'suggestion';
type ObjKey = `${DictionaryKey}_${number}`;

// From https://stackoverflow.com/a/57576688
type OneKey<K extends string, V = any> = {
  [P in K]: Record<P, V> & Partial<Record<Exclude<K, P>, never>> extends infer O
    ? { [Q in keyof O]: O[Q] }
    : never;
}[K];

type ClickType = 'result' | 'suggestion' | 'completion' | null;

type ButtonClickType =
  | 'navigation'
  | 'submit'
  | 'delete'
  | 'feedback'
  | 'contact'
  | 'search_term'
  | 'self_service'
  | 'top_article'
  | 'category'
  | 'appointment'
  | 'subcategory'
  | 'article'
  | 'faq_accordion'
  | 'ob_services'
  | 'open_snackbar';

export type WidgetName =
  | 'download_ios'
  | 'download_android'
  | 'in_online_banking'
  | 'am_telefon'
  | 'directions'
  | 'generics'
  | 'banner'
  | null;

//how each widget is being tracked by snowplow
export const widgetToTrackType: Record<WIDGET_TYPES, WidgetName> = {
  [WIDGET_TYPES.MOBILEAPPANDROID]: 'download_android',
  [WIDGET_TYPES.MOBILEAPPIOS]: 'download_ios',
  [WIDGET_TYPES.MOBILEAPPANDROIDQRCODE]: 'download_android',
  [WIDGET_TYPES.MOBILEAPPIOSQRCODE]: 'download_ios',
  [WIDGET_TYPES.ONLINEBANKING]: 'in_online_banking',
  [WIDGET_TYPES.TELEFONBUTTON]: 'am_telefon',
  [WIDGET_TYPES.TELEFONQRCODE]: 'am_telefon',
  [WIDGET_TYPES.DIRECTIONS]: 'directions',
  [WIDGET_TYPES.GENERICS]: 'generics',
  [WIDGET_TYPES.BANNER]: 'banner',
  [WIDGET_TYPES.NONE]: null,
};

export type WidgetTypeT = 'cta' | 'qr_code';

export type WidgetsOrderT = [WidgetName, WidgetName?, WidgetName?];

export type WidgetObject = {
  widget_name: WidgetName;
  widget_type: WidgetTypeT;
};

export type WidgetEntityType = {
  [key: `widget_${number}`]: WidgetObject | null;
};

export interface SearchEntityData extends SelfDescribingJson {
  data: {
    search_text: string;
    click_text: string;
    click_type: ClickType;
    page: number | null;
    click_position: number | null;
    results: {
      search_results: DictionaryData;
      search_suggestions: DictionaryData;
      search_widgets: WidgetEntityType | null;
    };
  };
}

export enum Step {
  WasChatHelpful = 'was_chat_helpful',
  WasHelpful = 'was_helpful',
  Submit = 'submit',
  WasVideoHelpful = 'was_video_helpful',
  VideoSubmit = 'video_feedback_submit',
}

const trackUserFeedback = (
  step: Step,
  response?: string[],
  customResponse?: string,
  contexts?: SelfDescribingJson[],
) => {
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.neugelb/user_feedback/jsonschema/2-0-2',
      data: {
        step: step,
        response: response,
        custom_response: customResponse,
      },
    },
    context: contexts,
  });
};

const trackButtonClick = (
  buttonName: string,
  buttonType: ButtonClickType,
  contexts?: SelfDescribingJson[],
) => {
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.neugelb/button_click/jsonschema/2-0-2',
      data: {
        button_name: buttonName,
        button_type: buttonType,
      },
    },
    context: contexts,
  });
};

const trackLinkClick = (
  targetUrl?: string,
  elementId?: string,
  elementClasses?: string[],
  elementTarget?: string,
  contexts?: SelfDescribingJson[],
) => {
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.snowplowanalytics.snowplow/link_click/jsonschema/1-0-1',
      data: {
        targetUrl: targetUrl ?? '',
        elementId: elementId ?? '',
        elementClasses: elementClasses ?? [],
        elementTarget: elementTarget ?? '',
      },
    },
    context: contexts ?? [],
  });
};

const trackUserScrolling = () => {
  let aggregatedEvent = {
    pageViewId: '',
    minXOffset: 0,
    maxXOffset: 0,
    minYOffset: 0,
    maxYOffset: 0,
    numEvents: 0,
  };
  enableActivityTrackingCallback({
    minimumVisitLength: 10,
    heartbeatDelay: 10,
    callback: function (event) {
      aggregatedEvent = {
        pageViewId: event.pageViewId,
        minXOffset:
          aggregatedEvent.minXOffset < event.minXOffset
            ? aggregatedEvent.minXOffset
            : event.minXOffset,
        maxXOffset:
          aggregatedEvent.maxXOffset > event.maxXOffset
            ? aggregatedEvent.maxXOffset
            : event.maxXOffset,
        minYOffset:
          aggregatedEvent.minYOffset < event.minYOffset
            ? aggregatedEvent.minYOffset
            : event.minYOffset,
        maxYOffset:
          aggregatedEvent.maxYOffset > event.maxYOffset
            ? aggregatedEvent.maxYOffset
            : event.maxYOffset,
        numEvents: aggregatedEvent.numEvents + 1,
      };
    },
  });
  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState == 'hidden') {
      trackSelfDescribingEvent({
        event: {
          schema: 'iglu:com.acme/page_unload/jsonschema/1-0-0',
          data: {
            minXOffset: Math.max(0, Math.round(aggregatedEvent.minXOffset)),
            maxXOffset: Math.max(0, Math.round(aggregatedEvent.maxXOffset)),
            minYOffset: Math.max(0, Math.round(aggregatedEvent.minYOffset)),
            maxYOffset: Math.max(0, Math.round(aggregatedEvent.maxYOffset)),
            activeSeconds: aggregatedEvent.numEvents * 10,
          },
        },
      });
    }
  });
};

const trackWidgetInteraction = (
  widgetName: WidgetName,
  widgetType: WidgetTypeT,
  targetUrl: string,
  contexts?: SelfDescribingJson[],
) => {
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.neugelb/widget_interact/jsonschema/1-0-2',
      data: {
        widget_name: widgetName,
        widget_type: widgetType,
        target_url: targetUrl,
      },
    },
    context: contexts ?? [],
  });
};

const trackSearch = (step: string, contexts?: SelfDescribingJson[]) => {
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.neugelb/search/jsonschema/2-0-0',
      data: {
        step: step,
      },
    },
    context: contexts ?? [],
  });
};

//this is part of the search context
const createEntityFromWidgets = (
  widgetTypes: WIDGET_TYPES[] | null,
): WidgetEntityType | null => {
  if (!widgetTypes) return null;

  const entity: WidgetEntityType = {};

  for (let i = 0; i < widgetTypes.length; i++) {
    const widgetName = widgetToTrackType[widgetTypes[i]];
    const isQrCode =
      widgetTypes[i] === WIDGET_TYPES.TELEFONQRCODE ||
      widgetTypes[i] === WIDGET_TYPES.MOBILEAPPANDROIDQRCODE ||
      widgetTypes[i] === WIDGET_TYPES.MOBILEAPPIOSQRCODE;

    entity[`widget_${i + 1}`] = {
      widget_name: widgetName,
      widget_type: isQrCode ? 'qr_code' : 'cta',
    };
  }
  return entity;
};

export const createSearchEntity = (
  searchText: string,
  clickText: string,
  clickType: ClickType,
  pageNumber: number | null,
  results?: Array<SuggestionResultType>,
  suggestions?: Array<SuggestionResultType>,
  widgetTypes?: WIDGET_TYPES[] | null,
): SearchEntityData => {
  const createDictionary = (
    arr: Array<SuggestionResultType>,
    key: DictionaryKey,
  ): DictionaryData => {
    const obj: DictionaryData = {};

    const length = key === 'suggestion' ? 5 : 10;

    for (let i = 0; i < length; i++) {
      const k: ObjKey = `${key}_${i + 1}`;
      obj[k] = arr[i] && arr[i].name ? arr[i].name : '';
    }

    return obj;
  };

  const findOutPosition = (): number | null => {
    if (clickType === 'completion') {
      const position = Object.values(
        createDictionary(suggestions ?? [], 'suggestion'),
      ).findIndex((x) => x === `${searchText}${clickText}`);
      return position !== -1 ? position + 1 : null;
    }
    if (clickType === 'suggestion' || clickType === 'result') {
      const position = Object.values(
        createDictionary(results ?? [], 'result'),
      ).findIndex((x) => x === `${clickText}`);
      return position !== -1 ? position + 1 : null;
    }
    return null;
  };

  const position = findOutPosition();

  return {
    schema: 'iglu:com.neugelb/search_entity/jsonschema/1-0-7',
    data: {
      search_text: searchText,
      click_text: clickText,
      click_type: clickType,
      page: pageNumber,
      click_position: position,
      results: {
        search_results: createDictionary(results ?? [], 'result'),
        search_suggestions: createDictionary(suggestions ?? [], 'suggestion'),
        search_widgets: createEntityFromWidgets(widgetTypes ?? null),
      },
    },
  };
};

export interface PortalData extends Record<string, unknown> {
  product: string;
  question: string;
}

export const createPortalEntity = (
  product: string,
  question: string,
): SelfDescribingJson<PortalData> => {
  return {
    schema: 'iglu:com.neugelb/portal/jsonschema/1-0-0',
    data: {
      product: product,
      question: question,
    },
  };
};

export type TrackerContextT = {
  tracker?: BrowserTracker | null;
};

export const TrackerContext = createContext<TrackerContextT>({});
export const TrackerContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const forceTracking = useFeature('forceTracking');
  const [tracker, setTracker] = useState<BrowserTracker | null>(null);

  useEffect(() => {
    function handleConsentsInitialized(
      _event?: Event,
      defaultEnabled = forceTracking,
    ) {
      let enableSnowplowTracker =
        window.cookieBox?.getConsents('snowplow').consentStatus ??
        defaultEnabled;

      if (enableSnowplowTracker && !tracker) {
        const spTracker = newTracker('sp', trackerEndpoint, {
          appId: snowplowAppId,
          anonymousTracking: {
            withSessionTracking: true,
            withServerAnonymisation: true,
          },
          customHeaders: {
            'SP-Anonymous': '*',
          },
          resetActivityTrackingOnPageView: true,
          stateStorageStrategy: 'none',
          eventMethod: 'post',
          contexts: {
            webPage: true,
            session: false,
          },
          plugins: [LinkClickTrackingPlugin(), SiteTrackingPlugin()],
        });

        if (spTracker) {
          setTracker(spTracker);

          enableActivityTracking({
            minimumVisitLength: 5,
            heartbeatDelay: 5,
          });
          trackUserScrolling();
        }
      } else {
        setOptOutCookie('spOptOut', ['sp']);
      }
    }

    if (forceTracking) {
      handleConsentsInitialized(undefined, true);
    }

    if (!tracker) {
      window.addEventListener(
        'consents_initialized',
        handleConsentsInitialized,
      );
      return () => {
        window.removeEventListener(
          'consents_initialized',
          handleConsentsInitialized,
        );
      };
    }
  }, [forceTracking, tracker]);

  return (
    <TrackerContext.Provider value={{ tracker }}>
      {children}
    </TrackerContext.Provider>
  );
};

const snowplowAppId = process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID as string;
const snowplowEndpoint = process.env.NEXT_PUBLIC_SNOWPLOW_ENDPOINT as string;
const trackerEndpoint = snowplowEndpoint;
// process.env.NODE_ENV !== 'production' ? '0.0.0.0:9090' : snowplowEndpoint;

export function useTracker(_origin?: string) {
  const { tracker } = useContext(TrackerContext);

  if (tracker) {
    return {
      tracker,
      trackPageView,

      enableLinkClickTracking,
      trackSearch,
      trackLinkClick,
      trackButtonClick,
      trackUserScrolling,
      trackUserFeedback,
      trackWidgetInteraction,
    };
  }

  return {
    tracker,
  };
}
